import styled from 'styled-components'

export const ColorContainer = styled.div`
  background-color: ${({ theme }) => theme.colors.navy};
  width: 100%;
  display: flex;
  justify-content: center;
  color: white;
`

export const Container = styled.footer`
  width: 100%;
  max-width: ${({ theme }) => theme.breakpoints.desktop}; 
  padding: 24px 16px;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;

  @media (max-width: ${({ theme }) => theme.breakpoints.mobile}) {
    flex-direction: column;
    align-items: flex-start;
  }
`

export const SocialNet = styled.span`
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  & > span {
    padding: 20px 10px;
  }
`
