import styled from 'styled-components'

export const Box = styled.div`
  margin: 20px;
  transition: 0.5s;
  width: 350px;
  height: 215px;
  cursor: pointer;
  color: ${({ theme }) => theme.colors.navy};
  background-color: ${({ theme }) => theme.colors.lightGray};
  border-radius: 20px;
  overflow: hidden;


  &:hover {
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.6);
    transform: translateY(-10px);
  }

  &:hover > * {
    opacity: 1;
  }

  &:hover > img {
    opacity: 0.1;
  }
  `

export const Image = styled.img`
  width: 100%;
  height: 215px;
  display: block;
  opacity: 1;
  transition: 0.5s ease;
`

export const Content = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  word-wrap: normal;
  overflow: hidden;
  opacity: 0;
  transition: 0.5s ease-in-out;
  font-size: 20px;
  width: 100%;
  padding: 26px;
  text-align: center;
`

export const Name = styled.p`
  font-size: 24px;
  font-weight: bold;
`

export const Description = styled.p`
  font-size: 16px;
  font-style: italic;
  text-align: center;
  font-weight: bold;
`
