import styled from "styled-components"

import { Section } from "@components/Layout/styled"

export const ColorContainer = styled.div`
  background-color: ${({ theme }) => theme.colors.lightGray};
  width: 100%;
  display: flex;
  justify-content: center;
`

export const Container = styled(Section)`
  padding: 32px 16px;
  display: flex;
  justify-content: center;
  flex-direction: column;
`
export const Projects = styled.div`
display: flex;
  justify-content: space-around;
  align-items: center;
  flex-wrap: wrap;

  @media (max-width: ${({ theme }) => theme.breakpoints.mobile}) {
    justify-content: center;
  }
`