export const theme = {
  colors: {
    navy: "#252934",
    green: "#79EBA7",
    lightGray: "#F5F5F5",
    softGreen: "#16A085"
  },
  breakpoints: {
    desktop: '1200px',
    tablet: '720px',
    mobile: '576px',
  }
}