import { theme } from "@utils/theme"
import { Backgound, Modal, Content, ModalHeader, CloseIcon } from "./styled"

function ProjectDescription({ title, isOpen = false, handleClose, children }) {
  return (
    isOpen && (
      <Backgound onClick={handleClose} onScroll={(e) => e.preventDefault()}>
        <Modal onClick={(e) => e.stopPropagation()}>
          <Content>
            <ModalHeader>
              {title}
              <CloseIcon
                onClick={handleClose}
                size={"1rem"}
                fill={theme.colors.lightGray}
              />
            </ModalHeader>
            {children}
          </Content>
        </Modal>
      </Backgound>
    )
  )
}

export default ProjectDescription
