import styled from "styled-components"

import {BaseHeader} from "@components/base/Header"

import { GrClose } from "react-icons/gr"

export const Backgound = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.4);
  z-index: 100;
`

export const Modal = styled.div`
  margin: 20vh auto;
  width: 70%;
  height: 60%;
  max-width: 720px;
  padding: 0 16px 16px;
  z-index: 150;
  background-color: white;
  border-radius: 15px;
  box-shadow: 0px 10px 5px 0px rgba(0, 0, 0, 0.5);

  @media (max-width: ${({theme}) => theme.breakpoints.mobile}) {
    margin: 20% auto;
    width: 95%;
    height: 80%;
  }
`

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  overflow: scroll;
  height: 100%;
`

export const Divider = styled.div`
  width: 100%;
  height: 2px;
  background-color: ${({ theme }) => theme.colors.lightGray};
`

export const ModalHeader = styled(BaseHeader)`
  position: sticky;
  top: 0;
  border-bottom: 2px solid ${({ theme }) => theme.colors.lightGray};
  background-color: white;
  padding: 16px 0;
  display: flex;
  justify-content: space-between;

`

export const CloseIcon = styled(GrClose)`
  cursor: pointer;
  
  @media (max-width: ${({theme}) => theme.breakpoints.mobile}) {
    width: 1.5rem;
    height: 1.5rem;
  }
`