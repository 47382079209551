//import DeLadoaLado from "@resources/LogoDLaL.png"
import UrbanCamp from "@resources/urbanCamp.png"
import GLogistics from "@resources/logistics.jpeg"
import Superviseitor from "@resources/calendar.jpg"
import Apli from "@resources/apli.png"

export const PROJECTS_DATA = (toggleState) => [
  {
    title: "Apli",
    image: Apli,
    description: "Web",
    stateKey: "Apli_isOpen",
    onClick: () => toggleState("Apli_isOpen"),
    content: [
      "I worked at Apple as a FrontEnd developer. There I had the opportunity to be in charge of different projects, some of them in the planning phase, others just in development, and even was fully responsible for one of our apps to deploy new releases, be the first responder in case of something went wrong and keep updated all the documentation related to it like change-logs, user guides and more. This though me how to take full ownership of a project and learned many valuable things about project management and technical concepts about the tools we use.",
      "In terms of development, some of the projects I'm more proud of are the integration of a fully working Code Editor, that is able to recognize the syntax of a customized language. Also added huge usability improvements to a Flow Editor, like navigation, shortcuts, auto-save mechanics, and more. As well, I created a Network Indicator that let the user know its current network status, helping to discard client-side errors. Moreover, I dedicated many hours to filter enhancements, getting involved with cache, infinite lists, SSR, pagination, UI/UX, etc.",
      "Finally, about the tools we use in the company, I'd say I feel comfortable using React, Redux, React-Query, Styled-Components, NextJS, ClickUp, Notion, Slack, Docker, Postman...",
    ],
  },
  {
    title: "Agreement Management System",
    image: GLogistics,
    description: "Web",
    stateKey: "SGA_isOpen",
    onClick: () => toggleState("SGA_isOpen"),
    content: [
      "For this project, I worked as an external consultant, hired by the company I used to work. It was a different methodology of working from the one I was used to because week by week we received new tickets or features with the expected date of delivery, so we had to organize ourselves to accomplish those deadline.",
      "In general, the software manages a record of meetings from the high-level employees, it allows the users to download all the minutes created for each event, share them by email or download them. The same users were the ones in charge of registering all the important events that happened in the meetings. Moreover, the application allows users to schedule new meetings, create virtual meetings in Microsoft Teams, invite collaborators, etc. After a meeting, users can be assigned new tasks and the responsible should keep track of the assignment by uploading their activities and progress.",
      "What was great about this project, is that I had the chance to be involved in all the features described above, it was a project we started from zero so knowing that is being used until this day, is something to be proud of."
    ]
  },
  {
    title: "Meals System",
    image: UrbanCamp,
    description: "Web",
    stateKey: "UBOX_isOpen",
    onClick: () => toggleState("UBOX_isOpen"),
    content: [
      "As an external consultant, I developed a web application for a gym that offered one service for their clients, which consisted in cooking all the meals for a day, following the diet of the nutritionist of the gym.",
      "Through the application, the clients could consult the menu scheduled for the week and register for the days they would like to have the menu. The orders could be canceled until 1 day before its scheduled. The clients even could add some special requirements for their meals, add allergies, if they are vegan, etc. The chefs could consult how many meals of every type they had to cook for the following days. And finally, the nutritionist had the ability to register the new menus and make changes to the current menus.",
      "The application was intended to be used on mobile so it had to be fully responsive. Also, it had a notification system for all kinds of users."
    ]
  },
  {
    title: "Administrative Management System",
    image: Superviseitor,
    description: "Web",
    stateKey: "SUP_isOpen",
    onClick: () => toggleState("SUP_isOpen"),
    content: [
      "This is one of the biggest projects I've been involved in. It's a web application for internal use of the company I used to work. Basically, it has all the tools needed to keep track of the employee's activities. In this project many people were involved, so I'll describe the features that I developed.",
      "The most difficult one was a task scheduler because if a user registers a task that overlaps with another task, the second task had to be rescheduled considering working hours, weekends, and priorities between tasks because the ones with higher priority couldn't be rescheduled, so there were occasions where tasks had to be partitioned. It was a recursive algorithm so it stopped iterating over tasks where all of them were correctly scheduled. As well, I created a kind of organigram, where the logged user could consult all the people he supervises and also all the coworkers that are above him. For the 2 features described, I developed the front and back end, mainly with JavaScript, Oracle Jet, and PHP.",
      "Other features were lists of users, tasks, projects, and settings with all the CRUD capabilities."
    ]
  },
  // {
  //   title: "PoS Coffee Shop",
  //   image: DeLadoaLado,
  //   description: "Web",
  //   stateKey: "TPV_isOpen",
  //   onClick: () => toggleState("TPV_isOpen"),
  // },
]
