import styled from "styled-components"

export const BaseHeader = styled.h3`
  font-weight: ${({ isBold }) => (isBold ? "bold" : "normal")};
  color: ${({ color }) => color};
  margin: 0;
  font-size: 1.5rem !important;
`

const Header = ({ children, isBold, color }) => (
  <BaseHeader isBold={isBold} color={color}>
    {children}
  </BaseHeader>
)

export default Header
