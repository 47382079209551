import Title from '@components/Title'
import FormContact from '@components/FormContact'

import { Container } from './styled'

const Contact = () => {
  return (
    <Container id="contact">
      <Title text='Contact' />
      <FormContact />
    </Container>
  )
}

export default Contact
