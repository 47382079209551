import React from "react"

import Text from "@components/base/Text"
import Header from "@components/base/Header"
import Title from "@components/Title"
import Interest from "@components/Interest"

import SebasArjona from "@resources/yo_hat.png"
import { theme } from "@utils/theme"

import {
  FaGamepad,
  FaCoffee,
  FaMoneyBillWave,
  FaBook,
  FaMotorcycle,
} from "react-icons/fa"

import {
  Container,
  Content,
  DataTable,
  Interests,
  Layout,
  MyPhoto,
  OneColumn,
  TwoColumns,
  EmailText
} from "./styled"

const AboutMe = () => {
  return (
    <Container id='aboutme'>
      <Title text='About me' />
      <Layout>
        <MyPhoto src={SebasArjona} width={300} />
        <Content>
          <Text>
            I enjoy creating quality software, thinking about the user in the
            first place, and making their experience with the product as
            enjoyable as I can. Love to work on difficult tasks for proving to
            myself what I'm capable of. Also, I'm a big fan of all the planning
            processes involved in a project.
          </Text>

          <TwoColumns>
            <OneColumn>
              <Header>Personal details</Header>
              <DataTable>
                {/* Row 1 */}
                <Text isBold={true} color={theme.colors.softGreen}>
                  Birthdate
                </Text>
                <EmailText>24/august/1999</EmailText>
                {/* Row 2 */}
                <Text isBold={true} color={theme.colors.softGreen}>
                  Nationality
                </Text>
                <Text>Mexican</Text>
                {/* Row 3 */}
                <Text isBold={true} color={theme.colors.softGreen}>
                  Email
                </Text>
                <EmailText>sebasarjona@outlook.com</EmailText>
                {/* Row 4 */}
                <Text isBold={true} color={theme.colors.softGreen}>
                  Degree
                </Text>
                <Text>Computer Engineer</Text>
              </DataTable>
            </OneColumn>
            <OneColumn>
              <Header>My interests</Header>
              <Interests>
                <Interest icon={<FaGamepad size={"2rem"} />} text={"Games"} />
                <Interest icon={<FaCoffee size={"2rem"} />} text={"Coffee"} />
                <Interest icon={<FaBook size={"2rem"} />} text={"Sci-Fi"} />
                <Interest
                  icon={<FaMotorcycle size={"2rem"} />}
                  text={"Drive"}
                />
                <Interest
                  icon={<FaMoneyBillWave size={"2rem"} />}
                  text={"Econ"}
                />
              </Interests>
            </OneColumn>
          </TwoColumns>
        </Content>
      </Layout>
    </Container>
  )
}

export default AboutMe
