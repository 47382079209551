import styled, { keyframes } from 'styled-components'

export const Container = styled.div`
  background-color: ${({ theme }) => theme.colors.navy};
  display: flex;
  flex-direction: column;
  width: 100%;
  min-height: 100vh;
  overflow: hidden;
  align-items: center;
  justify-content: center;
  text-align: center;
`

export const Text = styled.span`
  color: white;
  font-size: 30px;
  display: inline;
`

export const Name = styled.span`
  color: #79eba7;
`

export const ExpandMore = styled.div`
  display: flex;
  flex-direction: column;
  position: absolute;
  bottom: 50px;
  transform: translate(0, -50%);

  @media (max-width: ${({ theme }) => theme.breakpoints.mobile}) {
    bottom: 10px;
  }
`

const animate = keyframes`
  0% {
    opacity: 0;
    transform: rotate(45deg) translate(-20px, -20px );
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
    transform: rotate(45deg) translate(20px, 20px );
  }
`

export const Arrow = styled.span`
  display: block;
  width: 20px;
  height: 20px;
  border-bottom: 1px solid #fff;
  border-right: 1px solid #fff;
  transform: rotate(45deg);
  animation: ${animate} 2s infinite;

  &:nth-child(2) {
    animation-delay: -0.2s;
  }

  &:nth-child(3) {
    animation-delay: -0.4s;
  }
`

