import { ColorContainer, Container, Name, Options, Link } from "./styled"

const Navbar = () => {
  const goTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" })
  }

  return (
    <ColorContainer>
      <Container>
        <Name onClick={goTop}>Sebastián Arjona</Name>
        <Options>
          <Link href='#aboutme'>About me</Link>
          <Link href='#portfolio'>Portfolio</Link>
          <Link href='#contact'>Contact</Link>
        </Options>
      </Container>
    </ColorContainer>
  )
}

export default Navbar
