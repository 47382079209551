import styled from 'styled-components'

export const Form = styled.form`
  align-self: center;
  width: 80%;

  @media (max-width: ${({ theme }) => theme.breakpoints.tablet}) {
    width: 100%;
  }
`

export const ConfirmButton = styled.button`
  background-color: ${({ theme }) => theme.colors.green};
  border-color: ${({ theme }) => theme.colors.green};
  color: ${({ theme }) => theme.colors.navy};
  font-weight: bold;
  transition: 0.4s;

  &:hover {
    background-color: ${({ theme }) => theme.colors.navy};
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.mobile}) {
    width: 100%;
  }
`
