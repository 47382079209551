import styled from "styled-components"

export const Layout = styled.div`
  margin: 0;
  width: 100%;
  color: ${({theme}) => theme.colors.navy};
  display: flex;
  flex-direction: column;
  justify-content: center;
`

export const Section = styled.section`
  width: 100%;
  max-width: ${({ theme }) => theme.breakpoints.desktop}; 
  align-self: center;
`