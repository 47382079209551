import styled from "styled-components"

export const BaseText = styled.p`
  font-weight: ${({ isBold }) => (isBold ? "bold" : "normal")};
  color: ${({ color }) => color};
  margin: 0;
`

const Text = ({ children, isBold, color = "" }) => (
  <BaseText isBold={isBold} color={color}>
    {children}
  </BaseText>
)

export default Text
