import styled from "styled-components"

import { Section } from "@components/Layout/styled"
import { BaseText } from "components/base/Text"

export const Container = styled(Section)`
  padding: 32px 16px;
  display: flex;
  justify-content: center;
  flex-direction: column;
`

export const Layout = styled.div`
  display: flex;
  gap: 24px;
  justify-content: space-evenly;
  flex-wrap: wrap;
`

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  gap: 32px;
  max-width: 720px;
  width: 100%;
`


export const MyPhoto = styled.img`
  height: 100%;
  z-index: 0;
  opacity: 0.9;
`

export const TwoColumns = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 32px;

  @media (max-width: ${({ theme }) => theme.breakpoints.tablet}) {
    flex-direction: column;
  }
`

export const OneColumn = styled.div`
  display: flex;
  flex-direction: column;
  gap: 32px;
  flex: 1;
  width: 100%;
  min-width: 300px;
`

export const DataTable = styled.div`
  display: grid;
  grid-template-columns: auto 1fr;
  grid-gap: 24px;
  margin: auto;
`

export const Interests = styled.div`
  display: flex;
  flex-wrap: wrap;
  min-width: 250px;
  gap: 24px;

  @media (max-width: ${({theme}) => theme.breakpoints.tablet}) {
    justify-content: center;
  }
`

export const EmailText = styled(BaseText)`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`