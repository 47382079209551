import React, {useEffect} from 'react'

import { ThemeProvider } from 'styled-components'
import { theme } from '@utils/theme'

import Welcome from '@pages/Welcome'
import Navbar from '@components/Navbar'
import AboutMe from '@pages/AboutMe'
import Portfolio from '@pages/Portfolio'
import Contact from '@pages/Contact'
import Footer from '@components/Footer'

import { Layout } from '@components/Layout/styled'

const App = () => {
  
  useEffect(() => {
    document.title = 'Sebastián Arjona - Portfolio';
  });

  return (
    <ThemeProvider theme={theme}>
      <Layout>
        <Welcome />
        <Navbar />
        <AboutMe />
        <Portfolio />
        <Contact />
        <Footer />
      </Layout>
    </ThemeProvider>
  )
}

export default App
