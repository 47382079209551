import { SiFacebook, SiLinkedin, SiGithub } from "react-icons/si"

import { ColorContainer, Container, SocialNet } from "./styled"

const Footer = () => {
  const goToFacebook = () => {
    window.open("https://www.facebook.com/SebastianArjonaMendezA")
  }

  const goToLinkedIn = () => {
    window.open("https://www.linkedin.com/in/sebasti%C3%A1n-arjona-1934421b9/")
  }

  const goToGithub = () => {
    window.open("https://github.com/Arjona99")
  }

  return (
    <ColorContainer>
      <Container>
        <SocialNet onClick={goToFacebook}>
          <SiFacebook size='2em' />
          <span>Sebastián Arjona</span>
        </SocialNet>
        <SocialNet onClick={goToGithub}>
          <SiGithub size='2em' />
          <span>Arjona99</span>
        </SocialNet>
        <SocialNet onClick={goToLinkedIn}>
          <SiLinkedin size='2em' />
          <span>Sebastián Arjona</span>
        </SocialNet>
      </Container>
    </ColorContainer>
  )
}

export default Footer
