import React from "react"
import ReactDOM from "react-dom"
import "./index.css"
import reportWebVitals from "./reportWebVitals"
import "bootstrap/dist/css/bootstrap.min.css"
import App from "App.jsx"
import { HeadProvider, Link } from "react-head"

ReactDOM.render(
  <React.StrictMode>
    <HeadProvider>
      <Link
        href='https://fonts.googleapis.com/css?family=Poppins'
        rel='stylesheet'
        type='text/css'
      />
    </HeadProvider>

    <App />
  </React.StrictMode>,
  document.getElementById("root")
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
