import React from 'react'

import { Container, Text, Name, ExpandMore, Arrow } from './styled'

class Welcome extends React.Component {
  render() {
    return (
      <Container>
        <Text>
          Hi! I'm <Name>Sebastián Arjona</Name>,<br />
          Software Developer.
        </Text>

        <ExpandMore>
          <Arrow />
          <Arrow />
          <Arrow />
        </ExpandMore>
      </Container>
    )
  }
}

export default Welcome
