import { Container, Text, Line } from './styled'

const Title = ({ text }) => {
  return (
    <Container>
      <Text>{text}</Text>
      <Line />
    </Container>
  )
}

export default Title
