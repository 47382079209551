import { Box, Content, Description, Image, Name } from './styled'

const Project = ({ img, title, desc, onClick }) => {
  return (
    <Box onClick={onClick}>
      <Image src={img} alt={title} />
      <Content>
        <Name>{title}</Name>
        <Description>{desc}</Description>
      </Content>
    </Box>
  )
}

export default Project
