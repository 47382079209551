import Text from "@components/base/Text";

const { Box } = require("./styled");

const Interest = ({icon, text}) => (
  <Box>
    {icon}
    <Text>{text}</Text>
  </Box>
)

export default Interest