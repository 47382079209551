import React, { useState } from "react"

import Title from "@components/Title"
import Project from "@components/Project"

import { ColorContainer, Container, Projects } from "./styled"
import ProjectDescription from "@components/ProjectDescription"

import { PROJECTS_DATA } from "./constants"

const Portfolio = () => {
  const defaultState = {}
  const projectsData = PROJECTS_DATA(toggleState)
  projectsData.forEach((item) => (defaultState[item.stateKey] = false))
  const [state, setState] = useState(defaultState)

  function toggleState(value) {
    setState({ ...state, [value]: !state[value] }, true)
    if (!state[value]) document.body.style.overflow = 'hidden'
    else document.body.style.overflow = 'scroll'
  }

  function renderProjects() {
    return projectsData.map((item, key) => (
      <Project
        key={key}
        img={item.image}
        title={item.title}
        desc={item.description}
        onClick={item.onClick}
      />
    ))
  }

  function renderModals() {
    return projectsData.map((item, key) => (
      <ProjectDescription
        key={key}
        isOpen={state[item.stateKey]}
        title={item.title}
        handleClose={item.onClick}
      >
        {item.content?.map((s, key) => (
            <p key={key}>{s}</p>
        ))}
      </ProjectDescription>
    ))
  }

  return (
    <ColorContainer>
      <Container id='portfolio'>
        <Title text='Portfolio' />
        <Projects>{renderProjects()}</Projects>
        {renderModals()}
      </Container>
    </ColorContainer>
  )
}

export default Portfolio
