import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`
export const Text = styled.h1`
  font-size: 26px;
  font-weight: 600;
  margin: 10px 0;
`
export const Line = styled.div`
  border-top: 4px solid ${({ theme }) => theme.colors.navy};
  display: block;
  width: 100px;
  height: 50px;
`
