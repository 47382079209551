import styled from 'styled-components'

import { Section } from 'components/Layout/styled'

export const ColorContainer = styled.div`
  background-color: ${({ theme }) => theme.colors.navy};
  width: 100%;
  display: flex;
  justify-content: center;
  position: sticky;
  top: 0;
  padding: 24px 16px;
  z-index: 20;
  color: white;
`

export const Container = styled(Section)`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
    
`

export const Name = styled.div`
  cursor: pointer;
  white-space: nowrap;
  font-size: 24px;
`

export const Options = styled.div`
  color: white;
  text-decoration: none;
  text-transform: uppercase;

  @media (max-width: ${({ theme }) => theme.breakpoints.tablet}) {
    display: none;
  }
`

export const Link = styled.a`
  color: inherit;
  margin: 0 24px;
  &:hover {
    text-decoration: none;
    color: ${({ theme }) => theme.colors.green};
    transition: all 0.3s ease 0s;
  }
`
