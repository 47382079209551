import styled from "styled-components"

export const Box = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 8px;
  padding: 8px;
  margin: 0;
  background-color: ${({theme}) => theme.colors.lightGray};
  border-radius: 16px;
  width: 80px;
`