import React, { Fragment } from 'react';
import { useForm } from 'react-hook-form';

import { ConfirmButton, Form } from './styled'

const FormContact = () => {

    const { register, handleSubmit } = useForm();

    const onSubmit = (data) => {
      window.open(`mailto:sebasarjona@outlook.com?subject=[${data.name}]Contact from your portfolio&body=${data.message}`);
    }

    return (
        <Fragment>
            <Form onSubmit={handleSubmit(onSubmit)}>
                <div className="form-group w-100">
                    <label >Name:</label>
                    <input
                        id="form-nombre"
                        className="form-control mb-2"
                        name="name"
                        ref={register({
                            required: {
                                value: true,
                                message: 'Nombre es requerido'
                            }
                        })}
                    ></input>
                </div>
                <div className="form-group">
                    <label>Message:</label>
                    <textarea
                        id="form-mensaje"
                        className="form-control"
                        name="message"
                        rows="6"
                        ref={register({
                            required: {
                                value: true,
                                message: 'Mensaje es requerido'
                            }
                        })}
                    ></textarea>
                </div>

                <ConfirmButton type="submit" className="btn btn-primary">
                    Send
                </ConfirmButton>
            </Form>

        </Fragment>
    );
}

export default FormContact;